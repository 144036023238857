// ================================================================================================
// 	File Name: semi-bma-layout.scss
// 	Description: SCSS file for semi dark layout.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Load variable overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

body {
  &.semi-bma-layout {
    /************ Main Menu **************/

    // main menu
    .main-menu {
      background-color: $bma-primary2-color;
      .shadow-bottom {
        background: linear-gradient(
          180deg,
          $bma-primary2-color 44%,
          $bma-primary2-color 73%,
          $bma-primary2-color
        );
      }
      &:not(.expanded) {
        .navigation {
          .sidebar-group-active {
            a {
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
      &.theme-dark {
        .navbar-header {
          .brand-text {
            color: $gray-500 !important;
          }
          .modern-nav-toggle {
            svg {
              color: $gray-500 !important;
            }
          }
        }
        .navigation {
          .nav-item {
            &.active {
              a {
                background: linear-gradient(
                  118deg,
                  $gray-500,
                  rgba($gray-500, 0.7)
                );
                // background-color: #DCF9FB;

                box-shadow: 0 0 10px 1px rgba($gray-500, 0.7);
              }
            }
            .menu-content {
              li.active {
                background: linear-gradient(
                  118deg,
                  $gray-500,
                  rgba($gray-500, 0.7)
                );
                // background-color: #DCF9FB;
                box-shadow: 0 0 10px 1px rgba($gray-500, 0.7);
              }
            }
          }
        }
      }
    }

    // main menu content
    .main-menu-content {
      .navigation-main {
        background-color: $bma-primary2-color;
        .nav-item {
          svg {
            color: $theme-bma-text-color;
          }
          &.has-sub.open {
            a {
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
          li {
            &.active {
              a {
                background-color: transparent!important;
              }
            }
          }
          a:after,
          a {
            color: $theme-bma-text-color;
          }
          .menu-content {
            background-color: $bma-primary2-color;
            .active {
              .menu-item {
                color: $theme-bma-text-active-color;
              }
            }
            li {
              &:not(.active) {
                a {
                  background-color: $bma-primary2-color;
                }
              }
              &.hover {
                a {
                  color: $theme-bma-text-color;
                }
              }
            }
          }
        }
        .active {
          .menu-title,
          svg {
            color: $theme-bma-text-active-color;
          }
        }
        .sidebar-group-active {
          a {
            background: $bma-primary2-color;
            border-radius: 4px;
          }
          .menu-content {
            background-color: $bma-primary2-color;
            .active {
              z-index: 1;
              a {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    // menu collapsed active item color
    &.menu-collapsed {
      .main-menu:not(.expanded) {
        .navigation-main {
          li.active {
            a {
              background: rgba(0, 0, 0, 0.2) !important;
            }
          }
        }
      }
    }
  }
}
